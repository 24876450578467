<template>
    <div id="content">
        <div>
            <div class="card fitContent" style="margin-bottom: 1em">
                <h3 class="card_title" v-if="!information.part_type">Fertigungsart bestimmen</h3>
                <div class="padding cardBox"  v-if="!information.part_type">
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'machining'])">
                        <p><b>Zerspanung</b></p>
                        <div class="indent">
                            <p>Drehen</p>
                            <p>Fräsen</p>
                        </div>
                    </div>                
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'grinding'])">
                        <p><b>Schleifen</b></p>
                        <div class="indent">
                            <p></p>
                        </div>
                    </div>                    
                    <div class="box" @click="$store.commit('information_set', ['part_type', 'additive'])">
                        <p><b>Additiv</b></p>
                        <div class="indent">
                            <p>3D Druck</p>
                            <p>SLS</p>
                        </div>
                    </div>                    
                </div>
                <p v-else class="white radius padding_small pointer" @click="$store.commit('information_set', ['part_type', 0])">Andere Fertigungsart</p>
            </div>

            <div id="processList" class="card" v-if="information.part_type">
                <h3 class="card_title">Prozesse</h3>
                <div class="padding">
                    <div class="box">
                        <div @click="process_select(p)" :class="{blue: process_selected == p}" class="padding_small radius dark fitContent pointer" :key="p" v-for="(process, p) in processes_created">
                            <indicator :condition="verify.processes[p].met" required="true"></indicator>
                            {{dict(process.type)}}
                            <img @click="deleteProcess(p)" style="height: 1.25em" src="https://dashboard.pixelprime.de/assets/icons/delete.svg" alt="">
                        </div>
                        <div id="processNew" v-if="process_new">
                            <searchlist @select="newProcess($event)" class="marginAuto" :placeholder="'Prozess Wählen'" :values="processes_available"></searchlist>
                            <img class="invert pointer" @click="process_new = false" src="https://dashboard.pixelprime.de/assets/icons/light/decline.svg" alt="">
                        </div>
                        <div v-else-if="!processes_created.length" class="padding_small dark radius flex fitContent pointer" @click="process_new = true">
                            <img src="https://dashboard.pixelprime.de/assets/icons/add.svg" alt="">
                            <p class="marginAuto spacingright"><b>Neuer Prozess</b></p>
                        </div>
                        <p class="padding" v-if="newProcess_load">Lade neuen Prozess...</p>
                    </div>
                </div>
                <div id="chain" class="padding" v-if="processes_created.length">
                    <b>{{dict(selected_data.type)}}</b>
                    <div v-for="(subProcess, p) in selected_data.processChain" :key="p" class="indent">
                        <div @click="subProcess_select(subProcess)" :class="{blue: subProcess_selected == subProcess}" class="dark padding_small radius fitContent pointer flex">
                            <indicator :condition="verify.processes[process_selected].subProcesses[subProcess]" :required="selected_data.subProcesses[subProcess].required"></indicator>
                            <p>{{dict(subProcess)}}</p>
                        </div>
                        <!-- Check for Nested Processes: -->
                        <div v-for="(nested, n) in selected_data.subProcesses[subProcess].nestedProcesses" :key="n">
                            <div @click="subProcess_select(nested)" :class="{blue: subProcess_selected == nested}" v-if="checkNestedCondition(subProcess, nested)" class="indent dark pointer lightdark radius padding_small fitContent flex">
                                <indicator :condition="verify.processes[process_selected].subProcesses[nested]" :required="selected_data.subProcesses[nested].required"></indicator>
                                <p>{{dict(nested)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
        <div id="processOptions">
            <component :is="subProcess_selected"></component>
        </div>        

    </div>
</template>

<script>

export default {
    name: "Processes",
    data() {
        return {
            process_new: 0,
            newProcess_load: false,
        }
    },
    created() {
        this.processes_getAvailable()
    },
    mounted() {
        // let processes = document.createElement('script')
        // processes.setAttribute('src', 'https://development.primecalc.de/processes.js')
        // document.head.appendChild(processes)
    },
    computed: {
        information() {
            return this.$store.getters.information
        },
        processes_created() {
            return this.$store.state.calculation.processes || 0
        },
        processes_available() {
            return this.$store.state.processes_available
        },
        selected_data() {
            return this.$store.state.calculation.processes[this.process_selected] || 0
        },
        process_selected() {
            return this.$store.state.process_selected
        },
        subProcess_selected() {
            return this.$store.state.subProcess_selected
        },
        verify() {
            return this.$store.getters.verify
        },
        // time() {
        //     return p.getTime()
        // }
    },
    methods: {        
        dict(c) {  
            if(!this.$store.state.dict) return c
            return this.$store.state.dict[c] || c
        },
        processes_getAvailable() {
            const formData = new FormData();
            formData.append('method', "processes_getAvailable");
            fetch(this.$store.getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                this.$store.commit("setValue", ["processes_available", result])
            })
        },
        process_select(p) {
            this.$store.commit("process_select", p)
            // this.$store.state.process_selected = p
        },
        subProcess_select(p) {
            this.$store.state.subProcess_selected = p
        },
        newProcess(type) {
            this.process_new = false
            this.newProcess_load = true
            const formData = new FormData();
            formData.append('method', "processes_getNewProcesschain");
            formData.append('processType', type);
            fetch(this.$store.getters.path_data, {
                method: "POST",
                body: formData
            }).then(result => {
                return result.json()
            }).then(result => {
                this.newProcess_load = false
                this.$store.commit("addNewProcess", result)
            })
        },
        deleteProcess(p) {
            this.$store.commit("deleteProcess", p)
        },
        checkNestedCondition(subprocess, nested, p = this.$store.state.process_selected) {
            let parent = this.$store.state.calculation.processes[p].subProcesses[subprocess]
            nested = this.$store.state.calculation.processes[p].subProcesses[nested]
            let conditionsMet = true
            for (const cond in nested.condition) {
                if (parent.values[cond].value != nested.condition[cond]) {
                    conditionsMet = false
                }
            }
            return conditionsMet
        }
    }
}
</script>

<style scoped>
    /* .lightdark{background-color: rgb(250, 250, 250);} */
    .lightdark.indent{margin-left: 10px; margin-top: 2px;}
    .cardBox{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 1em;

    }
    .cardBox>div{
        transition: .25s;
        background-color: #f5f5f5;
        padding: 1em;
        cursor: pointer;
    }
    .cardBox>div:hover{
        background-color: #eef6ff;
    }
    #content{
        display: grid;   
        grid-template-columns: 1fr 5fr;
        grid-template-rows: auto;
        grid-template-areas: "list data" "list data";
    }
    #chain{
        grid-gap: 4px; 
        display: grid;
    }
    #processNew{
        display: grid;
        grid-template-columns: 1fr 2em;
    }
    #processList{
        grid-area: list;
    }
    #processOptions{
        grid-area: data;
    }
</style>





